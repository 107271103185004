import { Card, Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import SegmentDropdownFilters from "../SegmentDropdownFilters";
import DateTimeFilters from "../DateTimeFilters";
import AggregateFilter from "../AggregateFilter";
import ComparisonFilter from "../ComparisonFilter";
import "./controlPanel.scss";
import { WranglrDarkBlueTitle } from "../CubeJSPrototype";

const RoundedCard = styled(Card)`
    border-radius: 15px;
    border: 1px solid #d9d9d9;
`;

export const MarginCard = styled(RoundedCard)`
    margin-bottom: 20px;
`;

const FlexDisplay = styled(Row)`
    display: flex;
    justify-content: flex-end;
`;

const AggregatorsRow = styled(FlexDisplay)`
    margin-bottom: 10px;
`;

export default function ControlPanel(): JSX.Element {
    return (
        <MarginCard>
            <WranglrDarkBlueTitle level={4}>Filter Panel</WranglrDarkBlueTitle>
            <Row gutter={8}>
                <Col span={16}>
                    <DateTimeFilters />
                    <SegmentDropdownFilters />
                </Col>
                <Col span={8}>
                    <AggregatorsRow gutter={8}>
                        <Col span={16}>
                            <AggregateFilter />
                        </Col>
                    </AggregatorsRow>
                    <FlexDisplay gutter={8}>
                        <Col span={16}>
                            <ComparisonFilter />
                        </Col>
                    </FlexDisplay>
                </Col>
            </Row>
        </MarginCard>
    );
}
