import { BACKEND_API_URL } from "../Utils/constants";
import { getAccessToken } from "./firebase";

export interface Venue {
    primary_id: string;
}

export type Area = Venue;

export type Class = Venue;

export enum Segment {
    VENUE = "venues",
    AREA = "areas",
    CLASS = "classes",
}

// Commenting out test charts
export interface DashboardInfo {
    dashboardId: string;
    widgets: {
        "manhr-indicator": string;
        // "sales-over-time-average": string;
        "sales-over-time-day": string;
        // "sales-over-time-forever": string;
        // "sales-over-time-year": string;
        "test-chart-area": string;
        "test-chart-class": string;
        "test-chart-venue": string;
        "total-sales-indicator": string;
        "transaction-cumulative": string;
        // "transaction-cumulative-test": string;
    };
}

export interface GroupData {
    cubejsToken: string;
    dashboardInfo: DashboardInfo;
    groupId: string;
    segments: {
        areas: Area[];
        classes: Class[];
        group_id: string;
        venues: Venue[];
    };
    views: any;
}

export const getGroupData = () => {
    return getAccessToken()
        .then((access_token) => {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `bearer ${access_token}`);

            const requestOptions: RequestInit = {
                method: "GET",
                headers: myHeaders,
            };

            const url = `${BACKEND_API_URL}/v2/group/data`;
            return fetch(url, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    return data;
                })
                .catch(() => {
                    return [];
                });
        })
        .catch((error) => console.log(error));
};

export const addView = (viewLabel, filters) => {
    return getAccessToken().then((access_token) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            view_label: viewLabel,
            filters,
        });

        const requestOptions: RequestInit = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const url = `${BACKEND_API_URL}/v2/group/views`;
        return fetch(url, requestOptions);
    });
};

export const getViews = () => {
    return getAccessToken().then((access_token) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        const url = `${BACKEND_API_URL}/v2/group/views`;
        return fetch(url, requestOptions)
            .then((r) => r.json())
            .then((data) => data);
    });
};

export const getCubeJsToken = (): any => {
    return getGroupData().then((groupData: GroupData) => {
        return groupData.cubejsToken;
    });
};
