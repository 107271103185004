import React from "react";
import FilterDisplay from "./FilterDisplay";

interface Props {
    selectedHours: string[];
}

export default function TimeFilterDisplay({ selectedHours }: Props): JSX.Element {
    return (
        <FilterDisplay
            selectedItems={selectedHours}
            defaultColumns={2}
            customAllSelectedText={"All Times"}
            title={"Selected Times"}
        />
    );
}
