import { List, Tag } from "antd";
import React from "react";
import styled from "styled-components";
import { CentreAlignedTitle } from ".";

const FullWidthTag = styled(Tag)`
    width: 100%;
    text-align: center;
`;

const StyledListItem = styled(List.Item)`
    margin-bottom: 0 !important;
    padding: 3px 0 !important;
`;

interface Props {
    selectedItems: string[];
    defaultColumns: number;
    customAllSelectedText?: string;
    title: string;
}

export default function FilterDisplay({
    selectedItems,
    defaultColumns,
    customAllSelectedText,
    title,
}: Props): JSX.Element {
    const [dataSource, setDataSource] = React.useState<string[]>([]);
    const [columns, setColumns] = React.useState(defaultColumns);
    React.useEffect(() => {
        if (selectedItems.length > 0) {
            setDataSource(selectedItems);
            setColumns(defaultColumns);
        } else {
            setDataSource([customAllSelectedText ? customAllSelectedText : "All"]);
            setColumns(1);
        }
    }, [selectedItems]);

    return (
        <>
            <CentreAlignedTitle level={5}>{title}</CentreAlignedTitle>
            <List
                style={{ padding: "0px" }}
                grid={{ gutter: 8, column: columns }}
                dataSource={dataSource}
                renderItem={(item) => (
                    <StyledListItem>
                        <FullWidthTag>{item}</FullWidthTag>
                    </StyledListItem>
                )}
            />
        </>
    );
}
