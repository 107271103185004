import React from "react";
import { Button, Select } from "antd";
import { Area, Class, Venue } from "../../Api/backend";
import styled from "styled-components";

const StyledSelect = styled(Select)`
    width: 100%;
`;

const Option = Select.Option;

interface Props {
    segmentOptions: Venue[] | Area[] | Class[];
    onChange;
    placeholder: string;
    selectedSegments: Venue[] | Area[] | Class[];
}

type SegmentType = Venue | Area | Class;

export default function SegmentDropdown({
    segmentOptions,
    onChange,
    placeholder,
    selectedSegments,
}: Props): JSX.Element {
    const [segments, setSegments] = React.useState<SegmentType[]>([]);
    const [segmentLoading, setSegmentLoading] = React.useState<boolean>(true);
    const [options, setOptions] = React.useState([]);
    const [open, setOpen] = React.useState<boolean>(false);

    React.useEffect(() => {
        setSegments(segmentOptions);
        setSegmentLoading(false);
    }, []);

    React.useEffect(() => {
        const children: any = [];

        segments.forEach((segment: SegmentType) =>
            children.push({
                key: segment.primary_id,
                value: segment.primary_id,
            })
        );
        setOptions(children);
    }, [segments]);

    const buttonOnClick = () => setOpen(false);

    const customDropDownRender = (menu) => {
        return (
            <>
                {menu}
                <Button type={"primary"} block onClick={buttonOnClick}>
                    DONE
                </Button>
            </>
        );
    };

    const onDropdownVisibleChange = (open: boolean) => {
        setOpen(open);
    };

    return (
        <>
            <StyledSelect
                mode="multiple"
                loading={segmentLoading}
                placeholder={placeholder}
                maxTagCount={1}
                showArrow={true}
                onChange={onChange}
                allowClear={true}
                dropdownRender={customDropDownRender}
                onDropdownVisibleChange={onDropdownVisibleChange}
                open={open}
                dropdownStyle={{ paddingBottom: 0 }}
                maxTagTextLength={8}
                options={options}
                value={selectedSegments!.map((segment) => segment.primary_id)}
            />
        </>
    );
}
