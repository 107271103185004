import React, { useContext } from "react";
import { Context, Filters, State } from "../../State/store";
import Charts from "./ChartsV2";
import { Spin } from "antd";
import CustomLoader from "../CustomLoader";
import { useCubeQuery } from "@cubejs-client/react";
import { getCumulativeTransactionQuery, getDateGranularity } from "../../Api/cube";
import { getCumulativeTranscationsLineGraphOptions } from "../../Utils/utils";
import moment from "moment";

const cumulativeSum = ((sum) => (value) => (sum += value))(0);

const cumSum = (dataSeries) => {
    dataSeries.forEach((item, index) => {
        if (index > 0) {
            dataSeries[index] = dataSeries[index - 1] + item;
        }
    });
    return dataSeries;
};

interface Props {
    height: number;
}

export default function CumulativeTransactionsLineGraphV2({
    height,
}: Props): JSX.Element {
    const [state] = useContext(Context);
    const { filters }: State = state;
    const { selectedComparison, selectedVenues }: Filters = filters;
    const [xAxis, setXAxis] = React.useState([]);
    const [currentPeriod, setCurrentPeriod] = React.useState([]);
    const [comparisonPeriod, setComparisonPeriod] = React.useState([]);
    const [options, setOptions] = React.useState({});
    const [series, setSeries] = React.useState<any>([]);

    const { resultSet, isLoading, error } = useCubeQuery(
        getCumulativeTransactionQuery(filters)
    );

    React.useEffect(() => {
        console.log(error);
    }, [error]);

    React.useEffect(() => {
        if (resultSet) {
            const s = resultSet.series();
            const currentSeries = s[0] !== undefined ? s[0].series : [];
            const filteredCurrentSeries: any = currentSeries.filter((row) => {
                const MINUTE_SEGMENTS = ["00", "15", "30", "45"];
                return MINUTE_SEGMENTS.includes(row["x"].slice(-9, -7));
            });

            const values = filteredCurrentSeries.map((row) => row["value"]);

            // If we have an aggregate selected, we need to modify the date to not be invalid as it is set to 1970 by default
            if (filters.selectedAggregate) {
                setXAxis(
                    filteredCurrentSeries.map((row) => {
                        return (
                            moment().format("YYYY-MM-DD") +
                            moment(row["x"]).format("THH:mm:ss.SSSS")
                        );
                    })
                );
            } else {
                setXAxis(filteredCurrentSeries.map((row) => row["x"]));
            }

            const cumulativeColumnData: any = cumSum(values);
            setCurrentPeriod(cumulativeColumnData);

            if (s[1]) {
                const comparisonSeries = s[1].series;
                const filteredComparisonSeries: any = comparisonSeries.filter(
                    (row) => {
                        const MINUTE_SEGMENTS = ["00", "15", "30", "45"];
                        return MINUTE_SEGMENTS.includes(row["x"].slice(-9, -7));
                    }
                );

                const values = filteredComparisonSeries.map((row) => row["value"]);

                const cumulativeColumnData: any = cumSum(values);
                setComparisonPeriod(cumulativeColumnData);
            } else {
                setComparisonPeriod([]);
            }
        }
    }, [resultSet]);

    React.useEffect(() => {
        if (xAxis !== []) {
            setOptions(
                getCumulativeTranscationsLineGraphOptions(
                    xAxis,
                    getDateGranularity(filters)
                )
            );
        }
    }, [xAxis]);

    React.useEffect(() => {
        if (currentPeriod !== []) {
            const s = [
                {
                    name: "Current Period",
                    data: currentPeriod,
                },
            ];
            if (comparisonPeriod.length !== 0) {
                s.push({
                    name: selectedComparison!,
                    data: comparisonPeriod,
                });
            }
            setSeries(s);
        }
    }, [currentPeriod, comparisonPeriod]);

    return (
        <>
            <Spin spinning={isLoading} indicator={<CustomLoader />}>
                <Charts options={options} series={series} height={height} />
            </Spin>
        </>
    );
}
