import React, { useContext } from "react";
import { Area, GroupData } from "../../Api/backend";
import { Context, Filters } from "../../State/store";
import SegmentDropdown from "./SegmentDropdown";

export default function AreasDropdown(): JSX.Element {
    const [state, dispatch] = useContext(Context);
    const { dashboard, filters } = state;
    const { selectedAreas }: Filters = filters;
    const { segments } = state.groupData as GroupData;
    const { areas } = segments;

    const onChange = (option, optionArray) => {
        const areas: Area[] = optionArray.map((option) => ({
            primary_id: option.key,
        }));

        const postsData = {
            selectedAreas: areas,
        };
        dispatch({ type: "ADD_AREAS_TO_FILTER", payload: postsData });
    };

    return (
        <>
            <SegmentDropdown
                segmentOptions={areas}
                onChange={onChange}
                placeholder={"Areas"}
                selectedSegments={selectedAreas}
            />
        </>
    );
}
