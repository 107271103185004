import { useContext } from "react";
import { Context, Filters, State } from "../../State/store";
import { Col, Collapse, Row, Typography } from "antd";
import React from "react";
import DateFilterDisplay from "./DateFilterDisplay";
import DayFilterDisplay from "./DayFilterDisplay";
import styled from "styled-components";
import "./filterDisplayPanel.scss";
import TimeFilterDisplay from "./TimeFilterDisplay";
import SegmentsFilterDisplayPanel from "./SegmentsFilterDisplayPanel";
import { WRANGLR_DARK_BLUE } from "../../Utils/constants";
import ViewsButtonGroup from "./ViewsButtonGroup";

const MarginRow = styled(Row)`
    margin-bottom: 10px;
`;

export const CentreAlignedTitle = styled(Typography.Title)`
    text-align: center;
    color: ${WRANGLR_DARK_BLUE} !important;
    font-size: 14px !important;
`;

export default function FilterPanel(): JSX.Element {
    const [state, _dispatch]: [State, any] = useContext(Context);
    const { filters }: State = state;
    const {
        selectedDates,
        selectedDays,
        selectedHours,
        selectedVenues,
        selectedClasses,
        selectedAreas,
    }: Filters = filters;

    const anySegmentsSelected =
        selectedVenues.length > 0 ||
        selectedAreas.length > 0 ||
        selectedClasses.length > 0;

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Collapse
                defaultActiveKey={["1", "2"]}
                className={"filter-display-panel"}
                bordered={false}
            >
                <Collapse.Panel header={"Date and Time"} key={1}>
                    <MarginRow>
                        <Col span={24}>
                            <DateFilterDisplay selectedDates={selectedDates} />
                        </Col>
                    </MarginRow>
                    {selectedDays.length > 0 && (
                        <MarginRow>
                            <Col span={24}>
                                <DayFilterDisplay selectedDays={selectedDays} />
                            </Col>
                        </MarginRow>
                    )}
                    {selectedHours.length > 0 && (
                        <MarginRow>
                            <Col span={24}>
                                <TimeFilterDisplay selectedHours={selectedHours} />
                            </Col>
                        </MarginRow>
                    )}
                </Collapse.Panel>
                {anySegmentsSelected && (
                    <Collapse.Panel header={"Segments"} key={2}>
                        <SegmentsFilterDisplayPanel
                            selectedVenues={selectedVenues}
                            selectedAreas={selectedAreas}
                            selectedClasses={selectedClasses}
                        />
                    </Collapse.Panel>
                )}
            </Collapse>
            <ViewsButtonGroup />
        </div>
    );
}
