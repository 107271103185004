import TopNavigation from "../Components/TopNavigation";
import React from "react";
import styled from "styled-components";
import { Content } from "antd/es/layout/layout";
import { Layout } from "antd";
import HomeDashboardMobile from "../Components/HomeDashboardMobile";
import CubeJSPrototype from "../Components/CubeJSPrototype";

const FlexContent = styled(Content)`
    display: flex;
    height: 100%;
`;

const FlexDirectionRowDiv = styled.div`
    flex-direction: row;
    min-height: 100%;
`;

const GreyBackgroundDiv = styled.div`
    background: #f0f2f5;
`;

const RelativePositionDiv = styled(GreyBackgroundDiv)`
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 0;
    width: 0;
`;

const MarginContent = styled(Content)`
    margin: 20px;
    height: 100%;
`;

export default function HomePage() {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const [isMobile, setIsMobile] = React.useState(windowWidth <= 500);
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    React.useEffect(() => {
        setIsMobile(windowWidth <= 500);
    }, [windowWidth]);

    return (
        <>
            {isMobile ? (
                <GreyBackgroundDiv>
                    <TopNavigation mode={"mobile"} />
                    <MarginContent>
                        <HomeDashboardMobile />
                    </MarginContent>
                </GreyBackgroundDiv>
            ) : (
                <Layout>
                    <FlexDirectionRowDiv>
                        <FlexContent>
                            {/*<SideNavigation />*/}
                            <RelativePositionDiv>
                                <TopNavigation mode={"desktop"} />
                                <MarginContent>
                                    <CubeJSPrototype />
                                </MarginContent>
                            </RelativePositionDiv>
                        </FlexContent>
                    </FlexDirectionRowDiv>
                </Layout>
            )}
        </>
    );
}
