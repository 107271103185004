import React from "react";
import { DAYS_OF_THE_WEEK } from "../DayFilter";
import FilterDisplay from "./FilterDisplay";

interface Props {
    selectedDays: string[];
}

export default function DayFilterDisplay({ selectedDays }: Props): JSX.Element {
    const [selectedDaysSorted, setSelectedDaysSorted] = React.useState<string[]>([]);

    React.useEffect(() => {
        const daysOfTheWeekMapped = {};
        DAYS_OF_THE_WEEK.forEach((day, index) => {
            daysOfTheWeekMapped[day] = index;
        });
        const sorted = selectedDays.sort((a, b) => {
            if (daysOfTheWeekMapped[a] > daysOfTheWeekMapped[b]) {
                return 1;
            } else {
                return -1;
            }
        });
        setSelectedDaysSorted(sorted);
    }, [selectedDays]);

    return (
        <FilterDisplay
            selectedItems={selectedDaysSorted}
            defaultColumns={3}
            customAllSelectedText={"All Days"}
            title={"Selected Days"}
        />
    );
}
