import React, { useContext } from "react";
import { Context, Filters, State } from "../../State/store";
import Charts from "./ChartsV2";
import { Spin } from "antd";
import { useCubeQuery } from "@cubejs-client/react";
import { getDateGranularity, getSalesOverTimeQuery } from "../../Api/cube";
import CustomLoader from "../CustomLoader";
import moment from "moment";
import { getSalesOverTimeLineGraphOptions } from "../../Utils/utils";

export function numFormatter(num) {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
        return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
        return Math.round(num); // if value < 1000, nothing to do
    }
}

export function currencyFormatter(num) {
    return "$" + numFormatter(num);
}

interface Props {
    height: number;
}

export default function SalesOverTimeLineGraphV2({ height }: Props): JSX.Element {
    const [state] = useContext(Context);
    const { dashboard, filters }: State = state;
    const { selectedAggregate }: Filters = filters;
    // const allFilters = Object.entries(filters).map(([, value]) => value);
    const [xAxis, setXAxis] = React.useState([]);
    const [sales, setSales] = React.useState([]);
    const [activeStaff, setActiveStaff] = React.useState([]);
    const [options, setOptions] = React.useState({});
    const [series, setSeries] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<any>(true);

    const { resultSet, isLoading, error } = useCubeQuery(
        getSalesOverTimeQuery(filters)
    );

    React.useEffect(() => {
        if (isLoading) {
            setLoading(true);
        }
        if (resultSet) {
            const resultSetSeries = resultSet.series();
            const transactionTotalsSeries: any = resultSetSeries[0]
                ? resultSetSeries[0].series.filter((row) => {
                      const MINUTE_SEGMENTS = ["00", "15", "30", "45"];
                      return MINUTE_SEGMENTS.includes(row["x"].slice(-9, -7));
                  })
                : [];
            const activeStaffSeries: any = resultSetSeries[1]
                ? resultSetSeries[1].series.filter((row) => {
                      const MINUTE_SEGMENTS = ["00", "15", "30", "45"];
                      return MINUTE_SEGMENTS.includes(row["x"].slice(-9, -7));
                  })
                : [];

            // If we have an aggregate selected, we need to modify the date to not be invalid as it is set to 1970 by default
            if (filters.selectedAggregate) {
                setXAxis(
                    transactionTotalsSeries.map((row) => {
                        return (
                            moment().format("YYYY-MM-DD") +
                            moment(row["x"]).format("THH:mm:ss.SSSS")
                        );
                    })
                );
            } else {
                setXAxis(transactionTotalsSeries.map((row) => row["x"]));
            }
            setActiveStaff(activeStaffSeries.map((row) => Math.round(row["value"])));
            setSales(transactionTotalsSeries.map((row) => Math.round(row["value"])));
            setLoading(false);
        }
    }, [isLoading]);

    React.useEffect(() => {
        if (xAxis !== []) {
            setOptions(
                getSalesOverTimeLineGraphOptions(xAxis, getDateGranularity(filters))
            );
        }
    }, [xAxis]);

    React.useEffect(() => {
        if (sales !== []) {
            setSeries([
                {
                    name: "Sales",
                    type: "line",
                    data: sales,
                },
                {
                    name: "Active Staff",
                    type: "column",
                    data: activeStaff,
                },
                // {
                //     name: "/Manhr",
                //     type: "line",
                //     data: manHour,
                // },
            ]);
        }
    }, [sales]);

    return (
        <>
            <Spin spinning={isLoading} indicator={<CustomLoader />}>
                <Charts options={options} series={series} height={height} />
            </Spin>
        </>
    );
}
