import * as queryString from "querystring";

export const SISENSE_URL = process.env.REACT_APP_SISENSE_URL;
export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;
export const WRANGLR_DARK_BLUE = "#1F497D";
export const WRANGLR_LIGHT_BLUE = "#517EB5";

export let FIREBASE_REFRESH_TOKEN: string | null = "";

if (process.env.REACT_APP_DEFAULT_FIREBASE_REFRESH_TOKEN) {
    FIREBASE_REFRESH_TOKEN = process.env.REACT_APP_DEFAULT_FIREBASE_REFRESH_TOKEN;
}

if (FIREBASE_REFRESH_TOKEN === "") {
    FIREBASE_REFRESH_TOKEN =
        queryString.parse(window.location.search).token !== undefined
            ? (queryString.parse(window.location.search).token as string)
            : window.localStorage.getItem("refresh_token");
}
