import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "../../Pages/HomePage";
import SomethingWentWrongPage from "../../Pages/SomethingWentWrongPage";
import { setFirebaseAccessToken } from "../../Api/firebase";
import { useHistory } from "react-router-dom";
import CubeJsProvider from "../CubeJsProvider";

export default function Router() {
    const history = useHistory();
    const [loaded, setLoaded] = React.useState(false);
    React.useEffect(() => {
        setFirebaseAccessToken()
            .then(() => {
                history.push("/");
                setLoaded(true);
            })
            .catch(() => {
                history.push("/something-went-wrong");
                setLoaded(true);
            });
    }, []);

    return (
        <>
            {loaded && (
                <CubeJsProvider>
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route path="*" component={SomethingWentWrongPage} />
                    </Switch>
                </CubeJsProvider>
            )}
        </>
    );
}
