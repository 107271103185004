import React, { useContext } from "react";
import { Context, Filters, State } from "../../State/store";
import styled from "styled-components";
import { Select } from "antd";

export enum Aggregate {
    AVERAGE_DAY = "Average Day",
    AVERAGE_WEEK = "Average Week",
    MEDIAN_DAY = "Median Day",
    MEDIAN_WEEK = "Median Week",
}
const aggregates: Aggregate[] = [
    Aggregate.MEDIAN_DAY,
    Aggregate.MEDIAN_WEEK,
    // Aggregate.AVERAGE_DAY,
    // Aggregate.AVERAGE_WEEK,
];

const StyledSelect = styled(Select)`
    width: 100%;
`;

export default function AggregateFilter(): JSX.Element {
    const [options, setOptions] = React.useState([]);
    const [state, dispatch] = useContext(Context);
    const { filters }: State = state;
    const { selectedComparison }: Filters = filters;

    React.useEffect(() => {
        const children: any = [];

        aggregates.forEach((aggregate: Aggregate) => {
            children.push({
                key: aggregate,
                value: aggregate,
            });
        });
        setOptions(children);
    }, []);

    const onChange = (selectedAggregate) => {
        const postsData = {
            selectedAggregate,
        };

        dispatch({ type: "ADD_AGGREGATE_TO_FILTER", payload: postsData });
    };

    return (
        <StyledSelect
            disabled={!!selectedComparison}
            placeholder="Aggregate"
            showArrow={true}
            onChange={onChange}
            allowClear={true}
            options={options}
        />
    );
}
