import { Card, Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import VenuesDropdown from "./VenuesDropdown";
import AreasDropdown from "./AreasDropdown";
import ClassSegmentsDropdown from "./ClassDropdown";

export const MarginCard = styled(Card)`
    margin-bottom: 20px;
`;

export default function SegmentDropdownFilters(): JSX.Element {
    return (
        <Row gutter={8}>
            <Col span={8}>
                <VenuesDropdown />
            </Col>
            <Col span={8}>
                <AreasDropdown />
            </Col>
            <Col span={8}>
                <ClassSegmentsDropdown />
            </Col>
        </Row>
    );
}
