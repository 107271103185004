import React from "react";
import { message, Select } from "antd";
import { Context, Filters } from "../../State/store";
import moment from "moment";
import { GroupData } from "../../Api/backend";

export interface View {
    view_id: number;
    view_label: string;
    filters: Filters;
}

export const EMPTY_FILTERS: Filters = {
    selectedVenues: [],
    selectedAreas: [],
    selectedClasses: [],
    selectedDates: {
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
    },
    selectedDays: [],
    selectedHours: [],
    selectedAggregate: null,
    selectedComparison: null,
};

export default function ViewSelect(props) {
    const { className } = props;
    const [viewOptions, setViewOptions] = React.useState<View[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [options, setOptions] = React.useState([]);
    const [state, dispatch] = React.useContext(Context);
    const { views } = state.groupData as GroupData;

    React.useEffect(() => {
        if (views !== undefined) {
            setViewOptions(
                views.views.map((view) => ({
                    view_id: view.view_id,
                    view_label: view.view_label,
                    filters: view.filters,
                }))
            );
            setLoading(false);
        }
    }, [views]);

    React.useEffect(() => {
        const children: any = [];
        viewOptions.forEach((view) => {
            children.push(
                <Select.Option key={view.view_id} value={view.view_id}>
                    {view.view_label}
                </Select.Option>
            );
        });
        setOptions(children);
    }, [viewOptions]);

    const onChange = (viewId) => {
        const view: View = viewOptions.find((view) => view.view_id === viewId)!;
        if (view === undefined) {
            //apply no filters if view not detected
            return;
        }
        const payload = {
            filters: view.filters,
        };
        dispatch({ type: "REPLACE_FILTERS", payload });
        message.success("View Applied Sucessfully", 1);
    };

    const onClear = () => {
        const payload = {
            filters: EMPTY_FILTERS,
        };
        dispatch({ type: "REPLACE_FILTERS", payload });
    };

    return (
        <Select
            loading={loading}
            placeholder={"Select View"}
            onChange={onChange}
            allowClear={true}
            onClear={onClear}
            className={className}
        >
            {options}
        </Select>
    );
}
