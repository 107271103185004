import React, { createContext, useReducer } from "react";
import Reducer from "./reducer";
import { Area, Class, GroupData, Venue } from "../Api/backend";
import { Aggregate } from "../Components/AggregateFilter";
import moment from "moment";
import { ComparisonEnums } from "../Components/ComparisonFilter";

export interface SelectedDates {
    fromDate?: string;
    toDate?: string;
}

export interface Filters {
    selectedVenues: Venue[];
    selectedAreas: Area[];
    selectedClasses: Class[];
    selectedDates: SelectedDates;
    selectedDays: string[];
    selectedHours: string[];
    selectedAggregate: Aggregate | null;
    selectedComparison: ComparisonEnums | null;
}

// TODO create dashboard interface
export interface State {
    filters: Filters;
    dashboard;
    groupData: GroupData | {};
}

const initialState: State = {
    filters: {
        selectedVenues: [],
        selectedAreas: [],
        selectedClasses: [],
        selectedDates: {
            fromDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
            toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        },
        selectedDays: [],
        selectedHours: [],
        selectedAggregate: null,
        selectedComparison: null,
    },
    groupData: {},
    dashboard: null,
};

interface Props {
    children: any;
}

// TODO replace with actual typing
const Store = ({ children }: Props): JSX.Element => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

// TODO replace with actual typing
export const Context = createContext<any>(initialState);
export default Store;
