import React, { useContext } from "react";
import { GroupData, Venue } from "../../Api/backend";
import { Context, Filters, State } from "../../State/store";
import SegmentDropdown from "./SegmentDropdown";

export default function VenuesDropdown(): JSX.Element {
    const [state, dispatch]: [State, any] = useContext(Context);
    const { dashboard, filters } = state;
    const { selectedVenues }: Filters = filters;
    const { segments } = state.groupData as GroupData;
    const { venues } = segments;

    const onChange = (option, optionArray) => {
        const venue_ids: Venue[] = optionArray.map((option) => ({
            primary_id: option.key,
        }));

        const postsData = {
            selectedVenues: venue_ids,
        };
        dispatch({ type: "ADD_VENUES_TO_FILTER", payload: postsData });
    };

    return (
        <>
            <SegmentDropdown
                segmentOptions={venues}
                onChange={onChange}
                placeholder={"Venues"}
                selectedSegments={selectedVenues}
            />
        </>
    );
}
