import { State } from "./store";
import { Action } from "./actions";

const Reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "ADD_GROUP_DATA":
            return {
                ...state,
                groupData: {
                    ...action.payload.groupData,
                },
            };
        case "UPDATE_VIEWS":
            return {
                ...state,
                groupData: {
                    ...state.groupData,
                    views: {
                        views: action.payload.views,
                    },
                },
            };
        case "REPLACE_FILTERS":
            return {
                ...state,
                filters: {
                    ...action.payload.filters,
                },
            };
        case "ADD_VENUES_TO_FILTER":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedVenues: action.payload.selectedVenues,
                },
            };
        case "ADD_AREAS_TO_FILTER":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedAreas: action.payload.selectedAreas,
                },
            };
        case "ADD_CLASS_TO_FILTER":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedClasses: action.payload.selectedClasses,
                },
            };
        case "ADD_DATES_TO_FILTER":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedDates: action.payload.selectedDates,
                },
            };
        case "ADD_DAYS_TO_FILTER":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedDays: action.payload.selectedDays,
                },
            };
        case "ADD_HOURS_TO_FILTER":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedHours: action.payload.selectedHours,
                },
            };
        case "ADD_AGGREGATE_TO_FILTER":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedAggregate: action.payload.selectedAggregate,
                },
            };
        case "ADD_COMPARISON_TO_FILTER":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedComparison: action.payload.selectedComparison,
                },
            };
        case "SET_DASHBOARD":
            return {
                ...state,
                dashboard: action.payload,
            };
        default:
            return state;
    }
};

export default Reducer;
