import { Button, Select } from "antd";
import React, { useContext } from "react";
import styled from "styled-components";
import { Context, Filters } from "../../State/store";

const { Option } = Select;

export const DAYS_OF_THE_WEEK = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];

const children: any = [];
DAYS_OF_THE_WEEK.forEach((day, index) => {
    children.push(
        <Option key={index + 1} value={day}>
            {day}
        </Option>
    );
});

const StyledSelect = styled(Select)`
    width: 100%;
`;

export default function DayFilter(): JSX.Element {
    const [open, setOpen] = React.useState<boolean>(false);
    const [state, dispatch] = useContext(Context);
    const { dashboard, filters } = state;
    const { selectedDays }: Filters = filters;

    // TODO add functionality to  select all when no options are selected
    function onChange(option, optionArray) {
        const selectedDayKeys = optionArray.map((item) => item.key);
        const selectedDayValues = optionArray.map((item) => item.value);
        const postsData = {
            selectedDays: selectedDayValues,
        };
        dispatch({ type: "ADD_DAYS_TO_FILTER", payload: postsData });
    }

    const buttonOnClick = () => setOpen(false);

    const customDropDownRender = (menu) => {
        return (
            <>
                {menu}
                <Button type={"primary"} block onClick={buttonOnClick}>
                    DONE
                </Button>
            </>
        );
    };

    const onDropdownVisibleChange = (open: boolean) => {
        setOpen(open);
    };

    return (
        <StyledSelect
            mode="multiple"
            placeholder="Day"
            onChange={onChange}
            allowClear={true}
            maxTagCount={1}
            showArrow={true}
            dropdownRender={customDropDownRender}
            onDropdownVisibleChange={onDropdownVisibleChange}
            open={open}
            dropdownStyle={{ paddingBottom: 0 }}
            value={selectedDays}
        >
            {children}
        </StyledSelect>
    );
}
