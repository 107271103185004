import { Button, Select } from "antd";
import React, { useContext } from "react";
import { Context, Filters } from "../../State/store";
import styled from "styled-components";

interface Hour {
    key: string;
    value: string;
}

const hours: Hour[] = [];
for (let i = 6; i < 30; i++) {
    const temp = i % 24;
    let currHour;
    let nextHour;
    if (temp < 10) {
        currHour = `0${temp}`;
        nextHour = temp !== 9 ? `0${temp + 1}:00` : `${temp + 1}:00`;
    } else {
        currHour = `${temp}`;
        nextHour = temp !== 23 ? `${temp + 1}:00` : `00:00`;
    }
    const hour: Hour = { key: `${currHour}:00 - ${nextHour}`, value: currHour };
    hours.push(hour);
}

const Option = Select.Option;

const StyledSelect = styled(Select)`
    width: 100%;
`;

export default function TimeFilter(): JSX.Element {
    const [options, setOptions] = React.useState([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [state, dispatch] = useContext(Context);
    const { dashboard, filters } = state;
    const { selectedHours }: Filters = filters;

    React.useEffect(() => {
        const children: any = [];

        hours.forEach((hour: Hour) => {
            children.push(
                <Option key={hour.key} value={hour.key}>
                    {hour.key}
                </Option>
            );
        });
        setOptions(children);
    }, []);

    //TODO replace with typing
    const onChange = (option, optionArray) => {
        const hours: string[] = optionArray.map((option) => option.key);
        const postsData = {
            selectedHours: hours,
        };
        dispatch({ type: "ADD_HOURS_TO_FILTER", payload: postsData });
    };

    const buttonOnClick = () => setOpen(false);

    const customDropDownRender = (menu) => {
        return (
            <>
                {menu}
                <Button type={"primary"} block onClick={buttonOnClick}>
                    DONE
                </Button>
            </>
        );
    };

    const onDropdownVisibleChange = (open: boolean) => {
        setOpen(open);
    };

    return (
        <>
            <StyledSelect
                mode="multiple"
                placeholder="Time"
                maxTagCount={1}
                showArrow={true}
                onChange={onChange}
                allowClear={true}
                dropdownRender={customDropDownRender}
                onDropdownVisibleChange={onDropdownVisibleChange}
                open={open}
                dropdownStyle={{ paddingBottom: 0 }}
                value={selectedHours}
            >
                {options}
            </StyledSelect>
        </>
    );
}
