import React from "react";
import { Col, Row, Typography } from "antd";
import SegmentTable from "./SegmentTable";
import { RoundedCard } from "./index";
import styled from "styled-components";
import { WRANGLR_DARK_BLUE } from "../../Utils/constants";

const ReducedPaddingCard = (props) => (
    <RoundedCard
        {...props}
        style={{ minHeight: "355px" }}
        bodyStyle={{ padding: "10px" }}
    >
        {props.children}
    </RoundedCard>
);

const PaddedTitle = styled(Typography.Title)`
    color: ${WRANGLR_DARK_BLUE} !important;
    padding-left: 5px;
    padding-top: 5px;
`;

export default function TableWidgetV2(): JSX.Element {
    return (
        <Row gutter={16}>
            <Col span={8}>
                <ReducedPaddingCard>
                    <PaddedTitle level={4}>Venues</PaddedTitle>
                    <SegmentTable
                        segment={"Venue"}
                        dispatchAction={"ADD_VENUES_TO_FILTER"}
                    />
                </ReducedPaddingCard>
            </Col>
            <Col span={8}>
                <ReducedPaddingCard>
                    <PaddedTitle level={4}>Areas</PaddedTitle>
                    <SegmentTable
                        segment={"Area"}
                        dispatchAction={"ADD_AREAS_TO_FILTER"}
                    />
                </ReducedPaddingCard>
            </Col>
            <Col span={8}>
                <ReducedPaddingCard>
                    <PaddedTitle level={4}>Classes</PaddedTitle>
                    <SegmentTable
                        segment={"Class"}
                        dispatchAction={"ADD_CLASS_TO_FILTER"}
                    />
                </ReducedPaddingCard>
            </Col>
        </Row>
    );
}
