import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { Area, Class, Venue } from "../../Api/backend";
import FilterDisplay from "./FilterDisplay";

interface ClassFilterDisplayProps {
    selectedClasses: Class[];
}

function ClassFilterDisplay({ selectedClasses }: ClassFilterDisplayProps) {
    return (
        <FilterDisplay
            selectedItems={selectedClasses.map((c) => c.primary_id)}
            defaultColumns={2}
            customAllSelectedText={"All Classes"}
            title={"Selected Classes"}
        />
    );
}

interface AreaFilterDisplayProps {
    selectedAreas: Area[];
}

function AreaFilterDisplay({ selectedAreas }: AreaFilterDisplayProps) {
    return (
        <FilterDisplay
            selectedItems={selectedAreas.map((area) => area.primary_id)}
            defaultColumns={2}
            customAllSelectedText={"All Areas"}
            title={"Selected Areas"}
        />
    );
}

interface VenueFilterDisplayProps {
    selectedVenues: Venue[];
}

function VenueFilterDisplay({ selectedVenues }: VenueFilterDisplayProps) {
    return (
        <FilterDisplay
            selectedItems={selectedVenues.map((venue) => venue.primary_id)}
            defaultColumns={2}
            customAllSelectedText={"All Venues"}
            title={"Selected Venues"}
        />
    );
}

const MarginRow = styled(Row)`
    margin-bottom: 10px;
`;

interface Props {
    selectedVenues: Venue[];
    selectedAreas: Area[];
    selectedClasses: Class[];
}

export default function SegmentsFilterDisplayPanel({
    selectedVenues,
    selectedAreas,
    selectedClasses,
}: Props) {
    return (
        <>
            {selectedVenues.length > 0 && (
                <MarginRow>
                    <Col span={24}>
                        <VenueFilterDisplay selectedVenues={selectedVenues} />
                    </Col>
                </MarginRow>
            )}
            {selectedAreas.length > 0 && (
                <MarginRow>
                    <Col span={24}>
                        <AreaFilterDisplay selectedAreas={selectedAreas} />
                    </Col>
                </MarginRow>
            )}
            {selectedClasses.length > 0 && (
                <MarginRow>
                    <Col span={24}>
                        <ClassFilterDisplay selectedClasses={selectedClasses} />
                    </Col>
                </MarginRow>
            )}
        </>
    );
}
