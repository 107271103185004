import moment from "moment";
import {
    currencyFormatter,
    numFormatter,
} from "../Components/CubeJSPrototype/SalesOverTimeLineGraphV2";

const calculateWeekTooltipString = (val) => {
    const curWeek = moment(val);
    const endOfWeek = moment(val).add(7, "days");
    return (
        curWeek.format("D MMM YYYY") +
        " to " +
        endOfWeek.format("D MMM YYYY") +
        " (Week " +
        curWeek.format("w") +
        ")"
    );
};

const formatXAxisTimestampBasedOnGranularity = (granularity, val) => {
    switch (granularity) {
        case "minute":
            return moment(val).format("HH:mm");
        case "hour":
            return moment(val).format("dddd HH:00");
        case "day":
            return moment(val).format("dddd, MMMM Do");

        case "week":
            return " Week " + moment(val).format("w (YYYY)");
        case "month":
            return moment(val).format("MMMM YYYY");
    }
};

const formatTooltipTimestampBasedOnGranularity = (granularity, val) => {
    switch (granularity) {
        case "minute":
            return moment(val).format("HH:mm");
        case "hour":
            return moment(val).format("ddd HH:mm");
        case "day":
            return moment(val).format("ddd, MMMM Do");

        case "week":
            return calculateWeekTooltipString(val);
        case "month":
            return moment(val).format("MMMM YYYY");
    }
};

export const getCumulativeTranscationsLineGraphOptions = (xAxis, granularity) => {
    return {
        chart: {
            id: "basic-line",
            toolbar: {
                tools: {
                    download: false,
                    pan: false,
                },
            },
        },
        xaxis: {
            type: "datetime",
            categories: xAxis,
            labels: {
                datetimeUTC: false,
                formatter: (val) => {
                    return formatXAxisTimestampBasedOnGranularity(granularity, val);
                },
            },
        },
        tooltip: {
            x: {
                formatter: (val) =>
                    formatTooltipTimestampBasedOnGranularity(granularity, val),
            },
        },
        colors: ["#517EB5", "#BFBFBF"],
        fill: {
            type: "gradient",
            gradient: {
                colorStops: [
                    [
                        {
                            color: "#517EB5",
                            opacity: 1,
                        },
                    ],
                    [
                        {
                            color: "#BFBFBF",
                            opacity: 0.5,
                        },
                    ],
                ],
            },
        },
        yaxis: {
            tickAmount: 4,
            min: 0,
            labels: {
                formatter: (num) => {
                    return currencyFormatter(num);
                },
            },
        },
        grid: {
            show: true,
        },
        stroke: {
            width: 5,
        },
    };
};

export const getSalesOverTimeLineGraphOptions = (xAxis, granularity) => ({
    chart: {
        id: "basic-line",
        toolbar: {
            tools: {
                download: false,
                pan: false,
            },
        },
    },
    xaxis: {
        type: "datetime",
        categories: xAxis,
        labels: {
            datetimeUTC: false,
            formatter: (val) => {
                return formatXAxisTimestampBasedOnGranularity(granularity, val);
            },
        },
    },
    tooltip: {
        x: {
            formatter: (val) =>
                formatTooltipTimestampBasedOnGranularity(granularity, val),
        },
    },
    yaxis: [
        {
            tickAmount: 4,
            min: 0,
            type: "numeric",
            title: {
                text: "Sales",
            },
            labels: {
                formatter: (num) => {
                    return currencyFormatter(num);
                },
            },
        },
        {
            opposite: true,
            labels: {
                formatter: (num) => {
                    return numFormatter(num);
                },
            },
        },
    ],
    stroke: {
        curve: ["smooth", "smooth", "stepline"],
        width: [5, 5, 3],
    },
    colors: ["#517EB5", "#BBDEFB", "#BC0170"],
});

export const calculatePercentageDifference = (originalValue, comparisonValue) =>
    ((originalValue - comparisonValue) / originalValue) * 100;
