import React, { useContext } from "react";
import { Context, State } from "../../State/store";
import { WRANGLR_DARK_BLUE } from "../../Utils/constants";
import { DashboardInfo, getGroupData, GroupData } from "../../Api/backend";
import { Card, Col, Row, Tabs, Typography } from "antd";
import styled from "styled-components";
import TotalSalesMetricV2 from "../CubeJSPrototype/TotalSalesMetricV2";
import { WranglrDarkBlueTitle } from "../CubeJSPrototype";
import ManHrMetricV2 from "../CubeJSPrototype/ManHrMetricV2";
import SegmentTable from "../CubeJSPrototype/SegmentTable";
import CumulativeTransactionsLineGraphV2 from "../CubeJSPrototype/CumulativeTransactionsLineGraphV2";
import SalesOverTimeLineGraphV2 from "../CubeJSPrototype/SalesOverTimeLineGraphV2";
import SegmentDropdownFilters from "../SegmentDropdownFilters";

const RoundedCard = styled(Card)`
    border-radius: 15px;
`;

const NoTouchTabPanel = styled(Tabs.TabPane)`
    touch-action: none;
`;

const MarginRow = styled(Row)`
    margin-bottom: 20px;
`;

const ElementTitleRow = ({ title }) => (
    <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
            <Typography.Title level={4} style={{ color: WRANGLR_DARK_BLUE }}>
                {title}
            </Typography.Title>
        </Col>
    </Row>
);

const MetricsCard = ({ children }) => (
    <RoundedCard style={{ borderRadius: "15[x" }} bodyStyle={{ padding: "10px" }}>
        {children}
    </RoundedCard>
);

export default function HomeDashboardMobile(): JSX.Element {
    const [initialFiltersApplied, setInitialFiltersApplied] = React.useState(false);
    const [dashboardInfo, setDashboardInfo] = React.useState<DashboardInfo | null>(
        null
    );
    const [state, dispatch]: [State, any] = useContext(Context);
    const [toggle, setToggle] = React.useState(false);
    const { groupData }: any = state;

    React.useEffect(() => {
        getGroupData().then((groupData: GroupData) => {
            const payload = {
                groupData,
            };
            // console.log(groupData);
            dispatch({ type: "ADD_GROUP_DATA", payload });
            setDashboardInfo(groupData.dashboardInfo);
        });
    }, []);

    const LoadedDashboardCards = (
        <>
            {groupData.cubejsToken && (
                <>
                    {/* Metrics Row */}
                    <Row gutter={8}>
                        <Col span={8}>
                            <MetricsCard>
                                <WranglrDarkBlueTitle level={5}>
                                    Sales
                                </WranglrDarkBlueTitle>
                                <TotalSalesMetricV2 level={3} />
                            </MetricsCard>
                        </Col>
                        <Col span={8}>
                            <MetricsCard>
                                <WranglrDarkBlueTitle level={5}>
                                    / Manhr
                                </WranglrDarkBlueTitle>
                                <ManHrMetricV2 level={3} />
                            </MetricsCard>
                        </Col>
                        <Col span={8}>
                            <MetricsCard>
                                {/*<TotalSalesMetric*/}
                                {/*    widgetId={*/}
                                {/*        dashboardInfo!.widgets[*/}
                                {/*            "total-sales-indicator"*/}
                                {/*        ]*/}
                                {/*    }*/}
                                {/*/>*/}
                            </MetricsCard>
                        </Col>
                    </Row>
                    {/* Cumulative Transacations */}
                    <ElementTitleRow title={"Cumulative Sales"} />
                    <Row>
                        <Col span={24}>
                            <MetricsCard>
                                <Tabs
                                    defaultActiveKey="1"
                                    onChange={() => setToggle(!toggle)}
                                >
                                    <NoTouchTabPanel tab="Cumulative Sales" key="1">
                                        {!toggle && (
                                            <CumulativeTransactionsLineGraphV2
                                                height={200}
                                            />
                                        )}
                                    </NoTouchTabPanel>
                                    <NoTouchTabPanel tab="Sales over Time" key="2">
                                        {toggle && (
                                            <SalesOverTimeLineGraphV2 height={200} />
                                        )}
                                    </NoTouchTabPanel>
                                </Tabs>
                                {/*<Carousel>*/}
                                {/*    <div>*/}
                                {/*        <CumulativeTransactionsLineGraphV2 height={200} />*/}
                                {/*    </div>*/}
                                {/*    <div>*/}
                                {/*        <SalesOverTimeLineGraphV2 height={200} />*/}
                                {/*    </div>*/}
                                {/*</Carousel>*/}
                            </MetricsCard>
                        </Col>
                    </Row>
                    <ElementTitleRow title={"Venues"} />
                    <Row>
                        <Col span={24}>
                            <MetricsCard>
                                <SegmentTable
                                    segment={"Venue"}
                                    dispatchAction={"ADD_VENUES_TO_FILTER"}
                                />
                            </MetricsCard>
                        </Col>
                    </Row>
                    <ElementTitleRow title={"Areas"} />
                    <Row>
                        <Col span={24}>
                            <MetricsCard>
                                <SegmentTable
                                    segment={"Area"}
                                    dispatchAction={"ADD_VENUES_TO_FILTER"}
                                />
                            </MetricsCard>
                        </Col>
                    </Row>
                    <ElementTitleRow title={"Classes"} />
                    <Row>
                        <Col span={24}>
                            <MetricsCard>
                                <SegmentTable
                                    segment={"Class"}
                                    dispatchAction={"ADD_VENUES_TO_FILTER"}
                                />
                            </MetricsCard>
                        </Col>
                    </Row>
                    <SegmentDropdownFilters />
                </>
            )}
        </>
    );

    return <>{LoadedDashboardCards}</>;
}
