import React, { useContext } from "react";
import { Class, GroupData } from "../../Api/backend";
import { Context, Filters, State } from "../../State/store";
import SegmentDropdown from "./SegmentDropdown";

export default function ClassSegmentsDropdown(): JSX.Element {
    const [state, dispatch]: [State, any] = useContext(Context);
    const { dashboard, filters } = state;
    const { selectedClasses }: Filters = filters;
    const { segments } = state.groupData as GroupData;
    const { classes } = segments;

    const onChange = (option, optionArray) => {
        const classSegments: Class[] = optionArray.map((option) => ({
            primary_id: option.key,
        }));

        const postsData = {
            selectedClasses: classSegments,
        };
        dispatch({ type: "ADD_CLASS_TO_FILTER", payload: postsData });
    };

    return (
        <>
            <SegmentDropdown
                segmentOptions={classes}
                onChange={onChange}
                placeholder={"Class"}
                selectedSegments={selectedClasses}
            />
        </>
    );
}
