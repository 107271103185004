import { Filters } from "../State/store";
import moment from "moment";
import { ComparisonEnums } from "../Components/ComparisonFilter";
import { Aggregate } from "../Components/AggregateFilter";

const getFilterSet = (filters: Filters, comparisonsEnabled = true) => {
    const filterSet: any = [];

    if (
        filters.selectedDates !== {} &&
        filters.selectedDates.fromDate !== undefined &&
        (!filters.selectedComparison || !comparisonsEnabled)
    ) {
        const { fromDate, toDate } = filters.selectedDates;
        filterSet.push({
            member: "TimeSalesSeries15mMaterial.shift",
            operator: "inDateRange",
            values: [fromDate, toDate],
        });
    }

    if (filters.selectedDays.length > 0) {
        const selectedDaysToNum = filters.selectedDays.map(
            (day, index) => `${index + 1}`
        );
        filterSet.push({
            member: "TimeSalesSeries15mMaterial.weekDay",
            operator: "equals",
            values: selectedDaysToNum,
        });
    }

    if (filters.selectedHours.length > 0) {
        const hourFilters = filters.selectedHours.map((hours) =>
            parseInt(hours.split(":")[0]).toString()
        );
        console.log(hourFilters);
        filterSet.push({
            member: "TimeSalesSeries15mMaterial.hour",
            operator: "equals",
            values: hourFilters,
        });
    }

    if (filters.selectedVenues.length > 0) {
        filterSet.push({
            member: "TimeSalesSeries15mMaterial.mappedVenue",
            operator: "equals",
            values: filters.selectedVenues.map((venue) => venue.primary_id),
        });
    }

    if (filters.selectedAreas.length > 0) {
        filterSet.push({
            member: "TimeSalesSeries15mMaterial.mappedArea",
            operator: "equals",
            values: filters.selectedAreas.map((area) => area.primary_id),
        });
    }

    if (filters.selectedClasses.length > 0) {
        filterSet.push({
            member: "TimeSalesSeries15mMaterial.mappedClass",
            operator: "equals",
            values: filters.selectedClasses.map(
                (classLabel) => classLabel.primary_id
            ),
        });
    }

    return filterSet;
};

const getcomparisonPeriod = (fromDate, toDate, selectedComparison) => {
    const start = moment(fromDate);
    const end = moment(toDate);

    const getSamePeriodLastYear = () => {
        const temp1 = start.subtract(1, "year");
        const temp2 = end.subtract(1, "year");
        return [temp1.format("YYYY-MM-DD"), temp2.format("YYYY-MM-DD")];
    };

    const getSameDayLastWeek = () => {
        const temp1 = start.subtract(7, "day");
        const temp2 = end.subtract(7, "day");
        return [temp1.format("YYYY-MM-DD"), temp2.format("YYYY-MM-DD")];
    };

    const getPreviousPeriod = () => {
        const daysDifference = end.diff(start, "days");
        const temp1 = start.subtract(daysDifference + 1, "day");
        const temp2 = end.subtract(daysDifference + 1, "day");
        return [temp1.format("YYYY-MM-DD"), temp2.format("YYYY-MM-DD")];
    };

    switch (selectedComparison) {
        case ComparisonEnums.SAME_PERIOD_LAST_YEAR:
            return [[fromDate, toDate], getSamePeriodLastYear()];
        case ComparisonEnums.SAME_DAY_LAST_WEEK:
            return [[fromDate, toDate], getSameDayLastWeek()];
        case ComparisonEnums.PREVIOUS_PERIOD:
            return [[fromDate, toDate], getPreviousPeriod()];
    }
};

const getTimeDimension = (filters: Filters, skipComparison = false) => {
    const { fromDate, toDate } = filters.selectedDates;
    if (filters.selectedAggregate) {
        return [
            {
                dimension: "TimeSalesSeries15mMaterial.sortablePeriodMedianDaily",
                granularity: "minute",
                dateRange: [fromDate, toDate],
            },
        ];
    }

    const timeDimension = [
        {
            dimension: "TimeSalesSeries15mMaterial.localisedPeriod",
            granularity: getDateGranularity(filters),
            dateRange: [fromDate, toDate],
        },
    ];
    if (filters.selectedComparison !== null && !skipComparison) {
        timeDimension[0]["compareDateRange"] = getcomparisonPeriod(
            fromDate,
            toDate,
            filters.selectedComparison
        );
    }

    return timeDimension;
};

export const getMeasures = (selectedAggregate, addActiveStaff = false) => {
    switch (selectedAggregate) {
        case Aggregate.MEDIAN_DAY || Aggregate.MEDIAN_WEEK:
            if (addActiveStaff) {
                return [
                    "TimeSalesSeries15mMaterial.transactionMedian",
                    "TimeSalesSeries15mMaterial.activeStaffMedian",
                ];
            }
            return ["TimeSalesSeries15mMaterial.transactionMedian"];
        case Aggregate.AVERAGE_DAY || Aggregate.AVERAGE_WEEK:
            return ["TimeSalesSeries15mMaterial.transactionAverage"];
    }
    if (addActiveStaff) {
        return [
            "TimeSalesSeries15mMaterial.transactionTotal",
            "TimeSalesSeries15mMaterial.activeStaff",
        ];
    } else {
        return ["TimeSalesSeries15mMaterial.transactionTotal"];
    }
};

const getOrdering = (filters: Filters) => {
    if (filters.selectedAggregate) {
        return {
            "TimeSalesSeries15mMaterial.sortablePeriodMedianDaily": "asc",
        };
    }
    return {
        "TimeSalesSeries15mMaterial.localisedPeriod": "asc",
    };
};

export const getCumulativeTransactionQuery = (filters: Filters) => {
    // if  median day then measure is transactionMedian and timeDimension is sortablePeriodMedianDaily
    // otherwise default

    let measures = ["TimeSalesSeries15mMaterial.transactionTotal"];

    if (filters.selectedAggregate) {
        measures = getMeasures(filters.selectedAggregate)!;
    }

    const query: any = {
        measures,
        timeDimensions: getTimeDimension(filters),
        order: getOrdering(filters),
        filters: getFilterSet(filters),
    };

    return query;
};

export const getSalesOverTimeQuery = (filters: Filters) => {
    const query: any = {
        measures: getMeasures(filters.selectedAggregate, true)!,
        timeDimensions: getTimeDimension(filters, true),
        order: getOrdering(filters),
        filters: getFilterSet(filters, false),
    };

    return query;
};

export const getDateGranularity = (filters: Filters) => {
    let dateGranularity;

    if (filters.selectedDates !== {}) {
        const { fromDate, toDate } = filters.selectedDates;
        const start = moment(fromDate);
        const end = moment(toDate);
        const daysDifference = end.diff(start, "days");

        switch (true) {
            case daysDifference < 1:
                dateGranularity = "minute";
                break;
            case daysDifference <= 7:
                dateGranularity = "hour";
                break;
            case daysDifference <= 31:
                dateGranularity = "day";
                break;
            case daysDifference <= 366:
                dateGranularity = "week";
                break;
            default:
                dateGranularity = "month";
        }
    } else {
        dateGranularity = "month";
    }

    return dateGranularity;
};

export const getSegmentTableQuery = (
    filters: Filters,
    segment: "Venue" | "Area" | "Class"
) => {
    // viable segments: Venue, Area, Class
    const { fromDate, toDate } = filters.selectedDates;
    const timeDimensions =
        filters.selectedComparison === null
            ? []
            : [
                  {
                      dimension: "TimeSalesSeries15mMaterial.localisedPeriod",
                      compareDateRange: getcomparisonPeriod(
                          fromDate,
                          toDate,
                          filters.selectedComparison
                      ),
                      dateRange: [fromDate, toDate],
                  },
              ];
    const query: any = {
        measures: getMeasures(filters.selectedAggregate, true),
        timeDimensions,
        filters: getFilterSet(filters),
        dimensions: [],
    };

    query.dimensions.push(`TimeSalesSeries15mMaterial.mapped${segment}`);

    return query;
};

// checking the minutes in date string to only include 15-minute increments
export const filterTo15Minutes = (xAxisPeriod) => (dataRow) => {
    const MINUTE_SEGMENTS = ["00", "15", "30", "45"];
    return MINUTE_SEGMENTS.includes(dataRow[xAxisPeriod].slice(-9, -7));
};
