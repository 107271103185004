import Chart from "react-apexcharts";
import React from "react";

export default function ChartsV2({ options, series, height }: any) {
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        if (options !== {} && series.length) {
            setVisible(true);
        }
    }, [options, series]);

    return (
        <>
            {visible && (
                <Chart
                    options={options}
                    series={series}
                    type="line"
                    width="100%"
                    height={height}
                />
            )}
        </>
    );
}
