import React, { useContext } from "react";
import { Card, Col, Row, Skeleton, Spin, Typography } from "antd";
import styled from "styled-components";
import "./dashboards.scss";
import { Context } from "../../State/store";
import ControlPanel, { MarginCard } from "../ControlPanel";
import FilterDisplayPanel from "../FilterDisplayPanel";
import { DashboardInfo, getGroupData, GroupData } from "../../Api/backend";
import CustomLoader from "../CustomLoader";
import { WRANGLR_DARK_BLUE } from "../../Utils/constants";
import CumulativeTransactionsLineGraphV2 from "./CumulativeTransactionsLineGraphV2";
import TotalSalesMetricV2 from "./TotalSalesMetricV2";
import SalesOverTimeLineGraphV2 from "./SalesOverTimeLineGraphV2";
import TableWidgetV2 from "./TableWidgetV2";
import ManHrMetricV2 from "./ManHrMetricV2";

const FullHeightDiv = styled.div`
    height: 100%;
`;

export const RoundedCard = styled(Card)`
    border-radius: 15px;
    border: 1px solid #d9d9d9;
`;

const FullHeightCard = styled(RoundedCard)``;

const MarginBottomRow = styled(Row)`
    margin-bottom: 20px;
`;

export const WranglrDarkBlueTitle = styled(Typography.Title)`
    color: ${WRANGLR_DARK_BLUE} !important;
`;

export default function CubeJSPrototype(): JSX.Element {
    const [dashboardInfo, setDashboardInfo] = React.useState<DashboardInfo | null>(
        null
    );
    const [_, dispatch] = useContext(Context);

    React.useEffect(() => {
        getGroupData().then((groupData: GroupData) => {
            console.log(groupData);
            const payload = {
                groupData,
            };
            // console.log(groupData);
            dispatch({ type: "ADD_GROUP_DATA", payload });
            setDashboardInfo(groupData.dashboardInfo);
        });
    }, []);

    const LoadedDashboardCards = (
        <>
            <MarginBottomRow gutter={20}>
                <Col span={16}>
                    <MarginBottomRow gutter={16}>
                        <Col span={8}>
                            <RoundedCard>
                                <WranglrDarkBlueTitle level={4}>
                                    Sales
                                </WranglrDarkBlueTitle>
                                <TotalSalesMetricV2 level={1} />
                            </RoundedCard>
                        </Col>
                        <Col span={8}>
                            <RoundedCard>
                                <WranglrDarkBlueTitle level={4}>
                                    / Manhr
                                </WranglrDarkBlueTitle>
                                <ManHrMetricV2 level={1} />
                            </RoundedCard>
                        </Col>
                    </MarginBottomRow>
                    <RoundedCard>
                        <Row>
                            <Col span={24}>
                                <WranglrDarkBlueTitle level={4}>
                                    Cumulative Transactions
                                </WranglrDarkBlueTitle>
                                <CumulativeTransactionsLineGraphV2 height={350} />
                            </Col>
                        </Row>
                    </RoundedCard>
                </Col>
                <Col span={8}>
                    <FullHeightCard>
                        <WranglrDarkBlueTitle level={4}>
                            Selected Filters
                        </WranglrDarkBlueTitle>
                        <FilterDisplayPanel />
                    </FullHeightCard>
                </Col>
            </MarginBottomRow>
            <MarginBottomRow>
                <Col span={24}>
                    <RoundedCard>
                        <WranglrDarkBlueTitle level={4}>
                            Sales Over Time
                        </WranglrDarkBlueTitle>
                        <SalesOverTimeLineGraphV2 height={350} />
                    </RoundedCard>
                </Col>
            </MarginBottomRow>
            <Row>
                <Col span={24}>{<TableWidgetV2 />}</Col>
            </Row>
        </>
    );

    const DashboardCardsLoading = (
        <>
            <MarginBottomRow gutter={20}>
                <Col span={16}>
                    <Card>
                        <Skeleton active />
                    </Card>
                </Col>
                <Col span={8}>
                    <FullHeightCard>
                        <Skeleton active />
                    </FullHeightCard>
                </Col>
            </MarginBottomRow>
            <MarginBottomRow>
                <Col span={24}>
                    <Card>
                        <Skeleton active />
                    </Card>
                </Col>
            </MarginBottomRow>
            <Card>
                <Row>
                    <Col span={12}>
                        <Skeleton active />
                    </Col>
                </Row>
            </Card>
        </>
    );

    return (
        <FullHeightDiv>
            {dashboardInfo === null ? (
                <Spin size={"large"} indicator={<CustomLoader />}>
                    <div>
                        <MarginCard>
                            <Skeleton />
                        </MarginCard>
                        {DashboardCardsLoading}
                    </div>
                </Spin>
            ) : (
                <>
                    <ControlPanel />
                    {LoadedDashboardCards}
                </>
            )}
        </FullHeightDiv>
    );
}
