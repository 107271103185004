import { Button, Col, Input, message, Modal, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { Context } from "../../State/store";
import { UpdateViewsAction, ReplaceFiltersAction } from "../../State/actions";
import { EMPTY_FILTERS } from "../ViewSelect";
import { addView, getViews } from "../../Api/backend";

const PaddedDiv = styled.div`
    padding: 16px;
`;

const showModal = (setIsModalVisible) => () => setIsModalVisible(true);

const handleCancel = (setIsModalVisible) => () => setIsModalVisible(false);

const SaveViewsButton = ({ state, dispatch, dashboard }) => {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [inputText, setInputText] = React.useState("");

    const handleOk = () => {
        addView(inputText, state.filters)
            .then()
            .then(() => {
                getViews().then((views) => {
                    const action: UpdateViewsAction = {
                        type: "UPDATE_VIEWS",
                        payload: {
                            views: views.views,
                        },
                    };
                    dispatch(action);
                });
            })
            .then(() => {
                setIsModalVisible(false);
                message.success("View Saved", 1);
            });
    };

    const onChange = (e) => {
        setInputText(e.target.value);
    };

    return (
        <>
            <Modal
                title="Please enter a name for your view"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel(setIsModalVisible)}
            >
                <Input type={"text"} onChange={onChange} />
            </Modal>
            <Button onClick={showModal(setIsModalVisible)} type={"primary"} block>
                Save
            </Button>
        </>
    );
};

const ClearViewsButton = ({ dispatch, dashboard }) => {
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    const handleOk = () => {
        setIsModalVisible(false);
        const action: ReplaceFiltersAction = {
            type: "REPLACE_FILTERS",
            payload: {
                filters: EMPTY_FILTERS,
            },
        };
        dispatch(action);
        message.success("Filters Cleared", 1);
    };

    return (
        <>
            <Modal
                title="Confirm"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel(setIsModalVisible)}
            >
                <p>Are you sure you want to clear all filters?</p>
            </Modal>
            <Button onClick={showModal(setIsModalVisible)} block>
                Clear
            </Button>
        </>
    );
};

export default function ViewsButtonGroup() {
    const [state, dispatch] = React.useContext(Context);
    const { dashboard } = state;
    return (
        <PaddedDiv>
            <Row gutter={8}>
                <Col span={12}>
                    <SaveViewsButton
                        state={state}
                        dispatch={dispatch}
                        dashboard={dashboard}
                    />
                </Col>
                <Col span={12}>
                    <ClearViewsButton dispatch={dispatch} dashboard={dashboard} />
                </Col>
            </Row>
        </PaddedDiv>
    );
}
