import React, { useContext } from "react";
import { Context, State } from "../../State/store";
import { useCubeQuery } from "@cubejs-client/react";
import { getCumulativeTransactionQuery, getMeasures } from "../../Api/cube";
import { currencyFormatter } from "./SalesOverTimeLineGraphV2";
import { Divider, Spin, Statistic, Typography } from "antd";
import styled from "styled-components";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";

export const Metric = styled(Typography.Title)`
    color: #6096d8 !important;
    text-align: center;
    margin-bottom: 0 !important;
`;

const CenteredStatistic = styled(Statistic)`
    text-align: center;
    font-size: 10px !important;
`;
const ReducedMarginDivider = styled(Divider)`
    margin-top: 5px;
    margin-bottom: 5px;
`;

interface Props {
    level;
}

export default function TotalSalesMetricV2({ level }: Props): JSX.Element {
    const [state] = useContext(Context);
    const { filters }: State = state;
    const [sumLoading, setSumLoading] = React.useState(true);
    const [totalSales, setTotalSales] = React.useState("-");
    const [comparisonPercentage, setComparisonPercentage] = React.useState<
        number | null
    >(null);

    const { resultSet, isLoading, error } = useCubeQuery(
        getCumulativeTransactionQuery(filters)
    );

    React.useEffect(() => {
        if (resultSet) {
            const resultSets = resultSet.decompose() as [];
            const calculatedSums: number[] = [];
            resultSets.forEach((currentResultSet: any) => {
                const [totalTransactionMapping] = getMeasures(
                    filters.selectedAggregate
                );
                calculatedSums.push(
                    currentResultSet
                        .rawData()
                        .map((row) => row[totalTransactionMapping])
                        .reduce((a, b) => a + b, 0)
                );
            });
            const [
                totalSalesCurrentPeriod,
                totalSalesPreviousPeriod,
            ] = calculatedSums;
            setTotalSales(currencyFormatter(totalSalesCurrentPeriod));

            if (totalSalesPreviousPeriod) {
                const percentage =
                    ((totalSalesCurrentPeriod - totalSalesPreviousPeriod) /
                        totalSalesCurrentPeriod) *
                    100;
                setComparisonPercentage(percentage);
            } else {
                setComparisonPercentage(null);
            }

            setSumLoading(false);
        }
    }, [resultSet]);

    return (
        <>
            <Spin spinning={isLoading} size={"small"}>
                <Metric level={level}>{totalSales}</Metric>
                {comparisonPercentage !== null && (
                    <>
                        <ReducedMarginDivider />
                        <CenteredStatistic
                            value={comparisonPercentage}
                            precision={0}
                            valueStyle={
                                comparisonPercentage >= 0
                                    ? { color: "#3f8600", fontSize: "16px" }
                                    : { color: "#cf1322", fontSize: "16px" }
                            }
                            prefix={
                                comparisonPercentage >= 0 ? (
                                    <CaretUpFilled />
                                ) : (
                                    <CaretDownFilled />
                                )
                            }
                            suffix="%"
                        />
                    </>
                )}
            </Spin>
        </>
    );
}
