import React, { useContext } from "react";
import { Context, Filters, State } from "../../State/store";
import styled from "styled-components";
import { Select } from "antd";
import moment from "moment";

export enum ComparisonEnums {
    PREVIOUS_PERIOD = "Previous Period",
    SAME_PERIOD_LAST_YEAR = "Same Period Last Year",
    SAME_DAY_LAST_WEEK = "Same Day Last Week",
}

interface Comparison {
    comparisonType: ComparisonEnums;
    period: moment.Moment[];
}

const comparisons: Comparison[] = [
    {
        comparisonType: ComparisonEnums.PREVIOUS_PERIOD,
        period: [moment(), moment()],
    },
    {
        comparisonType: ComparisonEnums.SAME_PERIOD_LAST_YEAR,
        period: [moment(), moment()],
    },
    {
        comparisonType: ComparisonEnums.SAME_DAY_LAST_WEEK,
        period: [moment(), moment()],
    },
];

const StyledSelect = styled(Select)`
    width: 100%;
`;

export default function ComparisonFilter() {
    const [options, setOptions] = React.useState([]);
    const [state, dispatch] = useContext(Context);
    const { filters }: State = state;
    const { selectedAggregate }: Filters = filters;

    React.useEffect(() => {
        const children: any = [];

        comparisons.forEach((comparison: Comparison) => {
            children.push({
                key: comparison.comparisonType,
                value: comparison.comparisonType,
            });
        });
        setOptions(children);
    }, []);

    const onChange = (option) => {
        const selectedComparison: Comparison | undefined = comparisons.find(
            (comparison: Comparison) => option === comparison.comparisonType
        );
        const postsData = {
            selectedComparison: selectedComparison?.comparisonType,
        };

        dispatch({ type: "ADD_COMPARISON_TO_FILTER", payload: postsData });
    };

    return (
        <StyledSelect
            disabled={!!selectedAggregate}
            placeholder="Comparison"
            showArrow={true}
            onChange={onChange}
            allowClear={true}
            options={options}
        />
    );
}
