import moment from "moment";
import React from "react";
import { FullWidthDatePicker } from "../DateFilter";
import { SelectedDates } from "../../State/store";
import { CentreAlignedTitle } from ".";

interface Props {
    selectedDates: SelectedDates;
}

export default function DateFilterDisplay({ selectedDates }: Props): JSX.Element {
    const { fromDate, toDate } = selectedDates;
    return (
        <>
            <CentreAlignedTitle level={5}>Selected Date Range</CentreAlignedTitle>
            <FullWidthDatePicker
                value={[moment(fromDate), moment(toDate)]}
                disabled={true}
            />
        </>
    );
}
