import React, { useContext } from "react";
import { Context, State } from "../../State/store";
import { useCubeQuery } from "@cubejs-client/react";
import { getMeasures, getSalesOverTimeQuery } from "../../Api/cube";
import { Spin } from "antd";
import { Metric } from "./TotalSalesMetricV2";
import { currencyFormatter } from "./SalesOverTimeLineGraphV2";

interface Props {
    level;
}

export default function ManHrMetricV2({ level }: Props): JSX.Element {
    const [state] = useContext(Context);
    const { filters }: State = state;
    const [manHr, setManHr] = React.useState("-");

    const { resultSet, isLoading, error } = useCubeQuery(
        getSalesOverTimeQuery(filters)
    );

    React.useEffect(() => {
        if (resultSet) {
            const [transactionTotalMapping, activeStaffMapping] = getMeasures(
                filters.selectedAggregate,
                true
            );
            const rawData = resultSet.rawData();
            const transactionTotalsSum = rawData
                .map((row: any) => row[transactionTotalMapping])
                .reduce((a, b) => a + b, 0);
            const activeStaffSum = rawData
                .map((row: any) => row[activeStaffMapping])
                .reduce((a, b) => a + b, 0);
            const manHrMetric = transactionTotalsSum / activeStaffSum;
            console.log(manHrMetric);
            setManHr(isFinite(manHrMetric) ? currencyFormatter(manHrMetric) : "$0");
        }
    }, [resultSet]);

    return (
        <Spin spinning={isLoading} size="small">
            <Metric level={level}>{manHr}</Metric>
        </Spin>
    );
}
