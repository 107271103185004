import moment from "moment";
import React, { useContext } from "react";
import { DatePicker } from "antd";
import { Context, Filters, SelectedDates } from "../../State/store";
import styled from "styled-components";
const { RangePicker } = DatePicker;

export const FullWidthDatePicker = styled(RangePicker)`
    width: 100%;
`;

const convertSelectedDatesIntoMoment = (
    selectedDates: SelectedDates
): [moment.Moment, moment.Moment] => {
    if (selectedDates === {}) {
        return [moment(), moment()];
    }
    return [moment(selectedDates.fromDate), moment(selectedDates.toDate)];
};

export default function DateFilter(): JSX.Element {
    const [state, dispatch] = useContext(Context);
    const { dashboard, filters } = state;
    const { selectedDates }: Filters = filters;
    const [currentMode, setCurrentMode] = React.useState<any>(["date", "date"]);

    const disabledDate = (current) => {
        return current && current > moment().endOf("day");
    };

    const onPanelChange = (value, mode) => {
        if (mode[0] === "decade") {
            mode[0] = "year";
        }
        setCurrentMode(mode);
    };

    const onChange = (dates, dateStrings: [string, string]) => {
        let selectedDates: SelectedDates = {
            fromDate: dateStrings[0],
            toDate: dateStrings[1],
        };

        if (dates === null) {
            const today = moment().format("YYYY-MM-DD");
            selectedDates = {
                fromDate: today,
                toDate: today,
            };
        }

        const { fromDate, toDate } = selectedDates;
        const postsData = {
            selectedDates: {
                fromDate: fromDate,
                toDate: toDate,
            },
        };
        dispatch({ type: "ADD_DATES_TO_FILTER", payload: postsData });
        setCurrentMode(["date", "date"]);
    };

    return (
        <FullWidthDatePicker
            mode={currentMode}
            disabledDate={disabledDate}
            onPanelChange={onPanelChange}
            ranges={{
                Today: [moment(), moment()],
                Yesterday: [moment().subtract(1, "d"), moment().subtract(1, "d")],
                "This Week": [moment().startOf("isoWeek"), moment()],
                "Last week": [
                    moment().subtract(1, "w").startOf("isoWeek"),
                    moment().subtract(1, "w").endOf("isoWeek"),
                ],
                "This Month": [moment().startOf("month"), moment()],
                "Last Month": [
                    moment().subtract(1, "w").startOf("month"),
                    moment().subtract(1, "w").endOf("month"),
                ],
                "This Year": [moment().startOf("year"), moment()],
                "Last Year": [
                    moment().subtract(1, "y").startOf("year"),
                    moment().subtract(1, "y").endOf("year"),
                ],
                Forever: [moment("2018-01-01"), moment()],
            }}
            onChange={onChange}
            value={convertSelectedDatesIntoMoment(selectedDates)}
        />
    );
}
