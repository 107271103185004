import { FIREBASE_REFRESH_TOKEN } from "../Utils/constants";
import moment from "moment";

const firebaseConfig = {
    apiKey: "AIzaSyAzquxIA8srsivzn5rvIJZbFDGAi2Vvcdg",
    authDomain: "wranglr-test.firebaseapp.com",
    databaseURL: "https://wranglr-test-default-rtdb.firebaseio.com",
    projectId: "wranglr-test",
    storageBucket: "wranglr-test.appspot.com",
    messagingSenderId: "630911901537",
    appId: "1:630911901537:web:3e7bbfb6f5c9e30a056f20",
};

// firebase.initializeApp(firebaseConfig);

export const setFirebaseAccessToken = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "refresh_token");
    urlencoded.append("refresh_token", FIREBASE_REFRESH_TOKEN!);
    window.localStorage.setItem("refresh_token", FIREBASE_REFRESH_TOKEN!);
    console.log(FIREBASE_REFRESH_TOKEN);
    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
    };

    return fetch(
        `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`,
        requestOptions
    )
        .then((r) => r.json())
        .then((data) => {
            if (data.error !== undefined) {
                throw new Error();
            }
            window.localStorage.setItem("access_token", data.access_token);
            window.localStorage.setItem(
                "access_token_generation_time",
                moment().format()
            );
        });
};

export const getAccessToken = (): Promise<string> => {
    // Check to see if access token is expired, if it is, refresh first
    const access_token = window.localStorage.getItem("access_token");
    if (access_token && access_token !== "undefined") {
        const generationTime = moment(
            window.localStorage.getItem("access_token_generation_time")
        );
        const expired = moment().diff(generationTime, "hours") >= 1;
        if (!expired) {
            return new Promise<string>((resolve) => {
                resolve(access_token);
            });
        }
    }
    return setFirebaseAccessToken().then((): string => {
        return window.localStorage.getItem("access_token")!;
    });
};
