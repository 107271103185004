import cubejs from "@cubejs-client/core";
import { getCubeJsToken } from "../../Api/backend";
import React from "react";
import { CubeProvider } from "@cubejs-client/react";

const cubejsApi = cubejs(async () => await getCubeJsToken(), {
    apiUrl: process.env.REACT_APP_CUBEJS_BACKEND_API_URL as string,
});

export default function CubeJsProvider(props) {
    return <CubeProvider cubejsApi={cubejsApi}>{props.children}</CubeProvider>;
}
