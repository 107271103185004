import React from "react";
import { Button, Col, Image, Menu, Row, Tooltip } from "antd";
import styled from "styled-components";
import ViewSelect from "../ViewSelect";
import wranglrLogo from "../../wranglr-logo.png";
import { Header } from "antd/es/layout/layout";
import { NavLink as Link } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";

const FlexCol = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FlexLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const WhiteHeader = styled(Header)`
    background: #fff;
    padding-left: 0;
    padding-right: 0;
    height: 65px;
`;

interface Props {
    mode: "desktop" | "mobile";
}

export default function TopNavigation({ mode }: Props): JSX.Element {
    const [current, setCurrent] = React.useState("mail");

    const handleClick = (e) => {
        setCurrent(e.key);
    };

    const StyledViewSelect = styled(ViewSelect)`
        min-width: ${mode === "desktop" ? "200px" : "100px"};
    `;

    const StyledViewSelectDiv = styled.div`
        margin-right: 22px;
    `;

    const DeleteButtonCol = styled(Col)`
        margin-right: 44px;
    `;

    return (
        <WhiteHeader>
            <Row style={{ borderBottom: "1px solid #d9d9d9" }}>
                <FlexCol flex={mode === "desktop" ? "200px" : "100px"}>
                    <FlexLink to={"/"}>
                        <Image src={wranglrLogo} preview={false} width={"70%"} />
                    </FlexLink>
                </FlexCol>
                <Col flex={"auto"}>
                    <Menu
                        onClick={handleClick}
                        selectedKeys={[current]}
                        mode="horizontal"
                    >
                        <Menu.Item key="home">
                            <Link to={"/"}>Home</Link>
                        </Menu.Item>
                        {/*<Menu.Item key="app" disabled icon={<AppstoreOutlined />}>*/}
                        {/*    Forecasting*/}
                        {/*</Menu.Item>*/}
                    </Menu>
                </Col>
                <Col flex={mode === "desktop" ? "100px" : "50px"}>
                    <StyledViewSelectDiv>
                        <StyledViewSelect />
                    </StyledViewSelectDiv>
                </Col>
                {mode === "desktop" && (
                    <DeleteButtonCol>
                        <Tooltip
                            placement={"bottomRight"}
                            title={"Delete currently selected view"}
                        >
                            <Button icon={<DeleteOutlined />} />
                        </Tooltip>
                    </DeleteButtonCol>
                )}
            </Row>
        </WhiteHeader>
    );
}
