import { Col, Row } from "antd";
import DateFilter from "../DateFilter";
import React from "react";
import styled from "styled-components";
import DayFilter from "../DayFilter";
import TimeFilter from "../TimeFilter";

const MarginRow = styled(Row)`
    margin-bottom: 10px;
`;

export default function DateTimeFilters(): JSX.Element {
    return (
        <MarginRow gutter={8}>
            <Col span={8}>
                <DateFilter />
            </Col>
            <Col span={8}>
                <DayFilter />
            </Col>
            <Col span={8}>
                <TimeFilter />
            </Col>
        </MarginRow>
    );
}
