import { Table, Typography } from "antd";
import React, { useContext } from "react";
import { useCubeQuery } from "@cubejs-client/react";
import { getMeasures, getSegmentTableQuery } from "../../Api/cube";
import { Context, Filters } from "../../State/store";
import { ColumnType } from "antd/es/table";
import { currencyFormatter } from "./SalesOverTimeLineGraphV2";
import {
    AddAreasToFilterAction,
    AddClassToFilterAction,
    AddVenuesToFilterAction,
} from "../../State/actions";
import "./segmentTable.scss";
import styled from "styled-components";
import { WRANGLR_DARK_BLUE } from "../../Utils/constants";
import { calculatePercentageDifference } from "../../Utils/utils";

interface Props {
    segment: "Venue" | "Area" | "Class";
    dispatchAction:
        | AddVenuesToFilterAction["type"]
        | AddAreasToFilterAction["type"]
        | AddClassToFilterAction["type"];
}

// A basic interface of a record in the datasource. Comparative figures are optional, indicated by the '?'
interface Record {
    segment: string;
    sales: string;
    manHour: string;
    salesChange?: string;
    manHourChange?: string;
}

const getPayloadKey = (
    segment
): "selectedVenues" | "selectedAreas" | "selectedClasses" | "" => {
    switch (segment) {
        case "Venue":
            return "selectedVenues";
            break;
        case "Area":
            return "selectedAreas";
            break;
        case "Class":
            return "selectedClasses";
            break;
        default:
            return "";
    }
};

const getTitle = (segment): "Venues" | "Areas" | "Classes" | "" => {
    switch (segment) {
        case "Venue":
            return "Venues";
            break;
        case "Area":
            return "Areas";
            break;
        case "Class":
            return "Classes";
            break;
        default:
            return "";
    }
};

const PaddedTitle = styled(Typography.Title)`
    color: ${WRANGLR_DARK_BLUE} !important;
    padding-left: 5px;
    padding-top: 5px;
`;

const onHeaderCell = () => ({
    style: {
        color: WRANGLR_DARK_BLUE,
        fontWeight: "bold" as const,
    },
});

const getColumns = (comparisonSelected: boolean): ColumnType<Record>[] => {
    const columns: ColumnType<Record>[] = [];
    columns.push({
        title: `Name`,
        dataIndex: "segment",
        key: "segment",

        className: "segment-table-column",
        onHeaderCell,
    });
    columns.push({
        title: "Sales",
        dataIndex: "sales",
        key: "sales",

        className: "segment-table-column",
        onHeaderCell,
    });
    if (comparisonSelected) {
        columns.push({
            title: "% △",
            dataIndex: "salesChange",
            key: "salesChange",

            onHeaderCell,
        });
    }
    columns.push({
        title: "/Manhr",
        dataIndex: "manHour",
        key: "manHour",

        className: "segment-table-column",
        onHeaderCell,
    });
    if (comparisonSelected) {
        columns.push({
            title: `% △`,
            dataIndex: "manHourChange",
            key: "manHourChange",

            onHeaderCell,
        });
    }
    return columns;
};

const getComparisonFigures = (
    salesCurrentPeriod,
    manHrCurrentPeriod,
    salesPreviousPeriod,
    manHrPreviousPeriod
) => {
    const salesChange = calculatePercentageDifference(
        salesCurrentPeriod,
        salesPreviousPeriod
    );
    const manHourChange = calculatePercentageDifference(
        manHrCurrentPeriod,
        manHrPreviousPeriod
    );

    return {
        salesChange: `${Math.round(salesChange)}%`,
        manHourChange: isFinite(manHourChange)
            ? `${Math.round(manHourChange)}%`
            : "-",
    };
};

export default function SegmentTable({ segment, dispatchAction }: Props) {
    const [state, dispatch] = useContext(Context);
    const { filters }: { filters: Filters } = state;

    const selectedSegmentsFromState = filters[getPayloadKey(segment)];

    const [selectedSegments, setSelectedSegments] = React.useState([]);
    const [columns, setColumns] = React.useState<ColumnType<Record>[]>([]);
    const [dataSource, setDataSource] = React.useState<Record[]>([]);

    React.useEffect(() => {
        setSelectedSegments(
            selectedSegmentsFromState.map((segment) => segment.primary_id)
        );
    }, [selectedSegmentsFromState]);

    React.useEffect(() => {
        setColumns(getColumns(!!filters.selectedComparison));
    }, [filters.selectedComparison]);

    const filtersToQuery: Filters = { ...filters };
    filtersToQuery[getPayloadKey(segment)] = [];
    const { resultSet, isLoading, error } = useCubeQuery(
        getSegmentTableQuery(filtersToQuery, segment)
    );

    React.useEffect(() => {
        if (resultSet) {
            const results: any = [];
            (resultSet.decompose() as []).forEach((resultSet: any) =>
                results.push(resultSet.rawData())
            );

            const [currentPeriodData, comparePeriodData] = results;
            setDataSource(
                currentPeriodData
                    .map((row, index) => {
                        const [
                            transactionTotalMapping,
                            activeStaffMapping,
                        ] = getMeasures(filters.selectedAggregate, true);
                        const s = row[`TimeSalesSeries15mMaterial.mapped${segment}`];
                        const sales = row[transactionTotalMapping];
                        const manHour: string | number =
                            parseInt(row[transactionTotalMapping]) /
                            parseInt(row[activeStaffMapping]);
                        const manHourLabel = isFinite(manHour)
                            ? currencyFormatter(manHour)
                            : "$0";

                        // If we have comparison data, we want to first calculate the comparison figures
                        if (comparePeriodData) {
                            const previousPeriodRow = comparePeriodData.find(
                                (compareRow) =>
                                    compareRow[
                                        `TimeSalesSeries15mMaterial.mapped${segment}`
                                    ] === s
                            );
                            if (!previousPeriodRow) {
                                return {
                                    key: index,
                                    segment: s,
                                    sales: currencyFormatter(sales),
                                    manHour: manHourLabel,
                                };
                            }
                            const salesPreviousPeriod =
                                previousPeriodRow[transactionTotalMapping];
                            const manHourPreviousPeriod: string | number =
                                parseInt(salesPreviousPeriod) /
                                parseInt(previousPeriodRow[activeStaffMapping]);
                            const {
                                salesChange,
                                manHourChange,
                            } = getComparisonFigures(
                                sales,
                                manHour,
                                salesPreviousPeriod,
                                manHourPreviousPeriod
                            );

                            return {
                                key: index,
                                segment: s,
                                sales: currencyFormatter(sales),
                                salesChange,
                                manHour: manHourLabel,
                                manHourChange,
                            };
                        } else {
                            // If no comparison figures exist
                            return {
                                key: index,
                                segment: s,
                                sales: currencyFormatter(sales),
                                manHour: manHourLabel,
                            };
                        }
                    })
                    .filter((row) => row.segment !== null)
            );
        }
    }, [resultSet]);

    React.useEffect(() => {
        if (selectedSegmentsFromState.length !== selectedSegments.length) {
            const payload = {};

            // Build payload to send to global store
            payload[getPayloadKey(segment)] = selectedSegments.map((s) => ({
                primary_id: s,
            }));
            const action = {
                type: dispatchAction,
                payload,
            };

            dispatch(action);
        }
    }, [selectedSegments]);

    const onRowClick = (record) => () => {
        const s = record.segment;
        const selectedSegment = selectedSegments.find((segment) => segment === s);
        // If our selected row doesn't exist, add it to state. Otherwise unselect it from state
        if (selectedSegment === undefined) {
            setSelectedSegments(selectedSegments.concat(s));
        } else {
            setSelectedSegments(selectedSegments.filter((row) => row !== s));
        }
    };

    const setRowClassName = (record) => {
        if (
            selectedSegments.find(
                (selectedRecord) => selectedRecord === record.segment
            ) !== undefined
        ) {
            return "selected-row";
        }
        return "";
    };

    return (
        <>
            <Table
                className={"segment-table"}
                style={{ borderRadius: "15px" }}
                onRow={(record: Record) => {
                    return {
                        onClick: onRowClick(record),
                    };
                }}
                rowClassName={setRowClassName}
                loading={isLoading}
                columns={columns}
                dataSource={dataSource}
                pagination={{ pageSize: 5, size: "small" }}
                scroll={{ x: "max-content" }}
            />
        </>
    );
}
